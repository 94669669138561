import React, { useState, useEffect, useRef } from 'react';
import { FaFilter, FaTimes, FaSearch } from 'react-icons/fa';

// Interface for FiltersProps
interface FiltersProps {
  filters: {
    retailer: string;
    size: string;
    color: string;
    priceRange: [number, number];
    sortBy: string;
    gender: string;
    general_category: string;
  };
  onFilterChange: (filterName: string, value: any) => void;
  onSearch: (query: string) => void;
  isSearching?: boolean;
}

const sizes = ['XS', 'S', 'M', 'L', 'XL'];
const colors = ['Red', 'Blue', 'Green', 'Black', 'White'];
const genderOptions = ['Women', 'Men'];
const sortOptions = ['Featured', 'Price: Low to High', 'Price: High to Low', 'Newest Arrivals', 'Discount Amount'];

// Add categories for the dropdown
const categories = [
  'All',
  'Anklet', 'Apron', 'Bag', 'Bandana', 'Belt', 'Bodysuit', 'Boot', 'Bracelet', 'Cape',
  'Charm', 'Dress', 'Earring', 'Glasses', 'Glove', 'Hair', 'Hat', 'Headscarf',
  'Jersey', 'Jewelry Case', 'Jumpsuit', 'Keychain', 'Legging', 'Mask', 'Necklace',
  'Onesie', 'Outerwear', 'Pant', 'Poncho', 'Ring', 'Robe', 'Sari', 'Scarf', 'Set',
  'Shoe', 'Short', 'Skirt', 'Skort', 'Sock', 'Suit', 'Suspender', 'Sweater',
  'Swimsuit', 'Tie', 'Tights', 'Top', 'Underwear', 'Wallet', 'Watch'
];

const SearchBar = ({ onSearch }: { onSearch: (query: string) => void }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(inputValue);
  };

  const handleClear = () => {
    setInputValue('');
    inputRef.current?.focus();
  };

  return (
    <div className={`search-container ${isFocused ? 'focused' : ''}`}>
      <form onSubmit={handleSubmit}>
        <div className="search-input-wrapper">
          <FaSearch className="search-icon" />
          <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder="Search products..."
            className="search-input-field"
          />
          {inputValue && (
            <button
              type="button"
              className="clear-button"
              onClick={handleClear}
              aria-label="Clear search"
            >
              <FaTimes />
            </button>
          )}
          <button type="submit" className="submit-button">
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

// Filters Component
const Filters: React.FC<FiltersProps> = ({ filters, onFilterChange, onSearch, isSearching }) => {
  // Add a new state for brands
  const [brands, setBrands] = useState<Array<{ display: string; value: string }>>([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Add useEffect to set default gender when component mounts
  useEffect(() => {
    if (!filters.gender) {
      onFilterChange('gender', 'Men');
    }
  }, []);

  // Add useEffect to fetch brands
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        console.log('Initiating brands fetch...');
        const response = await fetch(
          'https://sage-ai-labs--list-scraping-targets-list-targets.modal.run'
        );
        
        console.log('Response status:', response.status);
        const data = await response.json();
        console.log('Received data:', data);

        if (data.status === '200' && Array.isArray(data.functions)) {
          const formattedBrands = data.functions.map((brand: string) => {
            const formatted = {
              display: brand
                .split('_')
                .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
              value: brand,
            };
            // console.log('Formatted brand:', formatted);
            return formatted;
          });
          
          // console.log('Final formatted brands:', formattedBrands);
          setBrands(formattedBrands);
        } else {
          console.warn('Unexpected data format:', {
            status: data.status,
            isFunctionsArray: Array.isArray(data.functions),
            receivedData: data
          });
        }
      } catch (error) {
        console.error('Error fetching brands:', {
          error,
          message: error instanceof Error ? error.message : 'Unknown error',
          stack: error instanceof Error ? error.stack : undefined
        });
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Helper function to format category value
  const formatApiValue = (category: string): string => {
    if (category === 'All') return '';
    return category.toLowerCase().replace(' ', '');
  };

  const FilterContent = () => (
    <>
      <SearchBar onSearch={onSearch} />

      <select
        value={filters.retailer}
        onChange={(e) => onFilterChange('retailer', e.target.value)}
      >
        <option value="">All Retailers</option>
        {brands.map((brand) => (
          <option key={brand.value} value={brand.value}>
            {brand.display}
          </option>
        ))}
      </select>

      {/* Size Dropdown */}
      <select
        value={filters.size}
        onChange={(e) => onFilterChange('size', e.target.value)}
      >
        <option value="">All Sizes</option>
        {sizes.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>

      {/* Color Dropdown */}
      <select
        value={filters.color}
        onChange={(e) => onFilterChange('color', e.target.value)}
      >
        <option value="">All Colors</option>
        {colors.map((color) => (
          <option key={color} value={color}>
            {color}
          </option>
        ))}
      </select>

      {/* Gender Dropdown */}
      <select
        value={filters.gender}
        onChange={(e) => onFilterChange('gender', e.target.value)}
      >
        <option value="">All Genders</option>
        {genderOptions.map((gender) => (
          <option key={gender} value={gender}>
            {gender}
          </option>
        ))}
      </select>

      {/* Category Dropdown */}
      <select
        value={filters.general_category}
        onChange={(e) => onFilterChange('general_category', formatApiValue(e.target.value))}
      >
        <option value="">All Categories</option>
        {categories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>

      {/* Sort By Dropdown */}
      <select
        value={filters.sortBy}
        onChange={(e) => onFilterChange('sortBy', e.target.value)}
      >
        {sortOptions.map((sortOption) => (
          <option key={sortOption} value={sortOption}>
            {sortOption === 'Featured' ? 'Trending' : sortOption}
          </option>
        ))}
      </select>
    </>
  );

  return (
    <>
      {/* Original filters for desktop */}
      <div className={`filters ${isScrolled ? 'scrolled' : ''} ${isSearching ? 'searching' : ''}`}>
        <FilterContent />
      </div>

      {/* Filter FAB for mobile */}
      <button 
        className="filter-fab"
        onClick={() => setIsModalOpen(true)}
        aria-label="Open filters"
      >
        <FaFilter />
      </button>

      {/* Filter modal for mobile */}
      {isModalOpen && (
        <div className={`filter-modal ${isModalOpen ? 'open' : ''}`}>
          <div className="filter-modal-header">
            <h3>Filters</h3>
            <button 
              className="close-modal"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close filters"
            >
              <FaTimes />
            </button>
          </div>
          <div className="filters">
            <FilterContent />
          </div>
        </div>
      )}
    </>
  );
};

export default Filters;
