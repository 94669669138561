import React from 'react';
import ProductList from './ProductList';
import { Product } from './ProductCard';

interface FavoritesProps {
  products: Product[];
  onToggleFavorite: (product: Product) => void;
}

const Favorites: React.FC<FavoritesProps> = ({ products, onToggleFavorite }) => {
  const favoriteProducts = products.filter((product) => product.isFavorite);

  return (
    <div className="favorites">
      {favoriteProducts.length > 0 ? (
        <ProductList products={favoriteProducts} onToggleFavorite={onToggleFavorite} />
      ) : (
        <p>You have no favorite products.</p>
      )}
    </div>
  );
};

export default Favorites;