import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ProductCard, { Product } from './ProductCard';
import './ProductList.css';

interface ProductListProps {
  products: Product[];
  onToggleFavorite: (product: Product) => void;
}

const ProductList: React.FC<ProductListProps> = ({ products, onToggleFavorite }) => {
  const [cardWidth, setCardWidth] = useState<number>(() => {
    const savedWidth = sessionStorage.getItem('cardWidth');
    return savedWidth ? parseInt(savedWidth) : 280;
  });
  const [hideRightmost, setHideRightmost] = useState<boolean>(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showSidebarHint, setShowSidebarHint] = useState(true);

  const uniqueProducts = useMemo(() => {
    const uniqueProductsMap = new Map();
    products.forEach((product) => {
      if (!uniqueProductsMap.has(product.product_id)) {
        uniqueProductsMap.set(product.product_id, product);
      }
    });
    return Array.from(uniqueProductsMap.values());
  }, [products]);

  const handleCardWidthChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newWidth = parseInt(event.target.value);
    setCardWidth(newWidth);
    sessionStorage.setItem('cardWidth', newWidth.toString());
  }, []);

  // useEffect(() => {
  //   // Log retailer and number of images for each unique product
  //   uniqueProducts.forEach(product => {
  //     console.log(`Retailer: ${product.retailer}, Number of images: ${product.images?.length || 0}`);
  //   });
  // }, [products]);

  // This function would be called when the sidebar is opened
  const handleSidebarOpen = () => {
    setHideRightmost(true);
  };

  // This function would be called when the sidebar is closed
  const handleSidebarClose = () => {
    setHideRightmost(false);
  };

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem('scrollPosition'); // Clear the stored position
    }
  }, []);

  // Add check for mobile
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  // Add scroll handler
  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 400);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Add scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Add useEffect to hide arrow after some time
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSidebarHint(false);
    }, 3000); // Hide after 3 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="product-list-container">
      {showSidebarHint && !isMobile && (
        <div className="sidebar-hint">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M15 18l-6-6 6-6"/>
          </svg>
        </div>
      )}
      {/* Only render scrub bar elements on desktop */}
      {!isMobile && (
        <>
          <div className="right-hover-area"></div>
          <div className="scrub-bar">
            <input
              type="range"
              min="200"
              max="650"
              step="1"
              value={cardWidth}
              onChange={handleCardWidthChange}
              className="card-width-slider"
            />
          </div>
        </>
      )}

      <div className={`product-grid ${hideRightmost ? 'hide-rightmost' : ''}`}>
        {uniqueProducts.map((product) => (
          <div 
            key={product.product_id}
            className="product-card-wrapper"
            style={{ '--card-width': `${cardWidth}px` } as React.CSSProperties}
          >
            <ProductCard
              product={product}
              onToggleFavorite={onToggleFavorite}
              cardWidth={cardWidth}
            />
          </div>
        ))}
      </div>

      <button 
        className={`back-to-top ${showBackToTop ? 'visible' : ''}`}
        onClick={scrollToTop}
      >
        BACK TO TOP
      </button>
    </div>
  );
};

export default ProductList;
