import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Filters from './components/Filters';
import Sidebar from './components/Sidebar';
import { Product } from './components/ProductCard';
import Home from './components/Home';
import Favorites from './components/Favorites';
import ProductDetails from './components/ProductDetails';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import ForYou from './components/ForYou';
import { ImageCacheProvider } from './contexts/ImageCacheContext';
import LoadingSpinner from './components/LoadingSpinner';

// Main App Component
const App: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [filters, setFilters] = useState({
    category: '',
    retailer: '',
    size: '',
    color: '',
    priceRange: [0, 1000000000] as [number, number],
    sortBy: 'Featured',
    gender: 'Men',
    general_category: '',
    trendTag: '',
  });
  const [favoriteProducts, setFavoriteProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [cartItems, setCartItems] = useState<Product[]>([]);

  // Pagination state
  const [lastProductId, setLastProductId] = useState<string | null>(null);
  const [lastSimilarity, setLastSimilarity] = useState<number | null>(null); // For 'Featured' sorting
  const [hasMore, setHasMore] = useState<boolean>(true);
  const productsPerPage = 100;

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  // Add new state for embedding pagination
  const [embeddingStart, setEmbeddingStart] = useState<number>(0);
  const embeddingLimit = 5; // Number of embeddings to fetch per page

  // New ref to keep track of previous filters
  const prevFiltersRef = useRef(filters);

  // Add this new state
  const [isSearching, setIsSearching] = useState<boolean>(false);

  // Function to compare filters and determine if we should clear products
  const shouldClearProducts = (prevFilters: any, newFilters: any) => {
    // List of filter keys to check
    const filterKeys = ['retailer', 'size', 'color', 'gender', 'general_category'];

    // Check if any of these filters have changed from a specific value back to ''
    for (const key of filterKeys) {
      if (prevFilters[key] && !newFilters[key]) {
        return true; // Filter was broadened; clear products
      }
    }

    // Check if sortBy has changed
    if (prevFilters.sortBy !== newFilters.sortBy) {
      return true; // sortBy changed; clear products
    }

    // Check if trendTag has changed
    if (prevFilters.trendTag !== newFilters.trendTag) {
      return true; // trendTag changed; clear products
    }

    // No conditions met; do not clear products
    return false;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      // Only handle mouse move if we're on the home page
      if (window.location.pathname === '/') {
        // Check if the mouse is over the header
        const isOverHeader = (event.target as Element)?.closest('[data-element="header"]');
        
        if (!isOverHeader) {
          const sidebarWidth = 200;
          if (event.clientX <= sidebarWidth) {
            setIsSidebarOpen(true);
          } else if (event.clientX > sidebarWidth && !sidebarRef.current?.contains(event.target as Node)) {
            setIsSidebarOpen(false);
          }
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Add useEffect to close sidebar when leaving home page
  useEffect(() => {
    if (window.location.pathname !== '/') {
      setIsSidebarOpen(false);
    }
  }, [window.location.pathname]);

  // Fetch products from the backend
  const fetchProducts = async (
    limit: number = 200,
    start_after: string | null = null,
    last_similarity: number | null = null,
    appliedFilters = filters,
    embeddingStart: number = 0  // New parameter
  ) => {
    try {
      setLoading(true);
      let url = 'https://sage-ai-labs--singlestore-backend-fetch-products.modal.run';

      // Build query parameters
      const params = new URLSearchParams();
      params.append('limit', limit.toString());
      if (start_after) {
        params.append('start_after', start_after);
      }
      if (appliedFilters.sortBy === 'Featured' && last_similarity !== null) {
        params.append('last_similarity', last_similarity.toString());
      }

      // Add trendTag parameter if it exists
      if (appliedFilters.trendTag) {
        params.append('trendtag', appliedFilters.trendTag);
      }

      // Map filters to server parameters
      if (appliedFilters.retailer) {
        params.append('retailer', appliedFilters.retailer);
      }
      if (appliedFilters.category) {
        params.append('category', appliedFilters.category);
      }
      if (appliedFilters.color) {
        params.append('color', appliedFilters.color);
      }
      if (appliedFilters.size) {
        params.append('size', appliedFilters.size);
      }
      if (appliedFilters.priceRange[0]) {
        params.append('min_price', appliedFilters.priceRange[0].toString());
      }
      if (appliedFilters.priceRange[1]) {
        params.append('max_price', appliedFilters.priceRange[1].toString());
      }

      // SortBy logic
      if (appliedFilters.sortBy) {
        let sortByField = '';
        let descending = false;

        if (appliedFilters.sortBy === 'Featured') {
          sortByField = 'featured';
        } else if (appliedFilters.sortBy.includes('Price')) {
          sortByField = 'current_price';
          descending = appliedFilters.sortBy === 'Price: High to Low';
        } else if (appliedFilters.sortBy === 'Newest Arrivals') {
          sortByField = 'updatedAt';
          descending = true;
        } else if (appliedFilters.sortBy === 'Discount Amount') {
          sortByField = 'discount_amount';
          descending = true;  // Higher discount amounts first
        }

        params.append('sort_by', sortByField);
        params.append('descending', descending.toString());
      }

      if (appliedFilters.gender) {
        let genderValue = '';
        if (appliedFilters.gender === 'Women') {
          genderValue = 'W';
        } else if (appliedFilters.gender === 'Men') {
          genderValue = 'M';
        }
        params.append('gender', genderValue);
      }

      if (appliedFilters.general_category) {
        params.append('general_category', appliedFilters.general_category);
      } else {
        params.append('general_category', '');
      }

      // Add embedding pagination parameters
      params.append('embedding_start', embeddingStart.toString());
      params.append('embedding_limit', embeddingLimit.toString());

      url += `?${params.toString()}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      if (data.status !== '200') {
        throw new Error(`Server error: ${data.error}`);
      }

      const uniqueProducts = data.products.reduce((acc: Product[], product: any) => {
        const mappedProduct: Product = {
          id: product.id,
          product_id: product.product_id,
          original_url: product.original_url,
          product_hash: product.product_hash,
          url: product.url,
          brand: product.brand,
          retailer: product.retailer,
          formatted_retailer: product.formatted_retailer,
          title: product.title,
          description: product.description,
          current_price: product.current_price,
          currency: product.currency,
          original_price: product.original_price,
          is_discounted: product.is_discounted,
          price_percentage_change: product.price_percentage_change,
          images: product.downloaded_images?.length
            ? product.downloaded_images
            : (console.log(`Using regular images for product ${product.id}`), product.images),
          color: product.color_name,
          sizes: product.sizes,
          gender: product.gender,
          tags: product.tags,
          category: product.category,
          review_data: product.review_data,
          additional_info: product.additional_info,
          feature_vector1: product.feature_vector1,
          feature_vector2: product.feature_vector2,
          best_deal_c: product.best_deal_c,
          best_deal_p: product.best_deal_p,
          cover_photo: product.cover_photo,
          created_at: product.created_at,
          day_price_drop_amount_c: product.day_price_drop_amount_c,
          day_price_drop_amount_p: product.day_price_drop_amount_p,
          discount_data: product.discount_data,
          downloaded_image: product.downloaded_image,
          downloaded_images: product.downloaded_images,
          fine_grained_category: product.fine_grained_category,
          general_category: product.general_category,
          has_any_deal: product.has_any_deal,
          has_day_price_drop: product.has_day_price_drop,
          has_month_price_drop: product.has_month_price_drop,
          has_official_discount: product.has_official_discount,
          has_week_price_drop: product.has_week_price_drop,
          month_price_drop_amount_c: product.month_price_drop_amount_c,
          month_price_drop_amount_p: product.month_price_drop_amount_p,
          official_discount_amount_c: product.official_discount_amount_c,
          official_discount_amount_p: product.official_discount_amount_p,
          popularity_data: product.popularity_data,
          price: product.price,
          price_history: product.price_history,
          product_handle: product.product_handle,
          sage_sizing: product.sage_sizing,
          updated_at: product.updated_at,
          week_price_drop_amount_c: product.week_price_drop_amount_c,
          week_price_drop_amount_p: product.week_price_drop_amount_p,
          clip_embedding_vector: product.clip_embedding_vector,
        };

        if (!acc.some((p) => p.id === mappedProduct.id)) {
          acc.push(mappedProduct);
        } else {
          console.warn(`Duplicate product ID found: ${mappedProduct.id}`);
        }
        return acc;
      }, []);
      console.log('Mapped Products:', uniqueProducts); // Log the product dictionary

      setProducts((prevProducts) => [...prevProducts, ...uniqueProducts]);

      if (data.next_start_after) {
        setHasMore(true);
        setLastProductId(data.next_start_after);
        if (appliedFilters.sortBy === 'Featured' && data.last_similarity) {
          setLastSimilarity(data.last_similarity);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch more data when scrolling
  const fetchMoreData = () => {
    if (loading || !hasMore) return;
    if (filters.sortBy === 'Featured') {
      fetchProducts(productsPerPage, lastProductId, lastSimilarity, filters, embeddingStart);
    } else {
      fetchProducts(productsPerPage, lastProductId, null, filters, embeddingStart);
    }
    setEmbeddingStart(prev => prev + embeddingLimit); // Increment embedding start
  };

  // Function to filter existing products based on new filters
  const filterProducts = (products: Product[], appliedFilters: any) => {
    return products.filter((product) => {
      // Apply filters
      let matches = true;

      // Retailer filter
      if (appliedFilters.retailer && appliedFilters.retailer !== product.retailer) {
        matches = false;
      }

      // Category filter
      if (appliedFilters.category && appliedFilters.category !== product.category) {
        matches = false;
      }

      // Color filter
      if (
        appliedFilters.color &&
        appliedFilters.color.toLowerCase() !== product.color.toLowerCase()
      ) {
        matches = false;
      }

      // **Size filter (Updated)**
      if (appliedFilters.size) {
        const sizeArray = product.sizes.availableSizes; // or product.sizes.allSizes
        if (!sizeArray.includes(appliedFilters.size)) {
          matches = false;
        }
      }

      // Gender filter
      if (appliedFilters.gender) {
        let genderValue = '';
        if (appliedFilters.gender === 'Women') {
          genderValue = 'W';
        } else if (appliedFilters.gender === 'Men') {
          genderValue = 'M';
        }
        if (genderValue !== product.gender) {
          matches = false;
        }
      }

      // General category filter
      if (
        appliedFilters.general_category &&
        appliedFilters.general_category !== product.general_category
      ) {
        matches = false;
      }

      // Price range filter
      if (appliedFilters.priceRange) {
        const [minPrice, maxPrice] = appliedFilters.priceRange;
        if (product.current_price < minPrice || product.current_price > maxPrice) {
          matches = false;
        }
      }

      return matches;
    });
  };

  // Derived variable for displayed products
  const displayedProducts = filterProducts(products, filters);

  // Fetch products when filters change
  useEffect(() => {
    const prevFilters = prevFiltersRef.current;
    const filtersHaveBroadened = shouldClearProducts(prevFilters, filters);

    if (filtersHaveBroadened) {
      // Clear existing products
      setProducts([]);
    }

    // Reset pagination state
    setLastProductId(null);
    setLastSimilarity(null);
    setHasMore(true);
    setEmbeddingStart(0);

    // Fetch more products to fill out the rest
    fetchProducts(productsPerPage, null, null, filters, 0);

    // Update previous filters
    prevFiltersRef.current = filters;
  }, [filters]);

  // Function to toggle favorite status
  const handleToggleFavorite = (product: Product) => {
    const updatedProducts = products.map((p) =>
      p.id === product.id ? { ...p, isFavorite: !p.isFavorite } : p
    );
    setProducts(updatedProducts);

    setFavoriteProducts((prevFavorites) =>
      !product.isFavorite
        ? [...prevFavorites, { ...product, isFavorite: true }]
        : prevFavorites.filter((p) => p.id !== product.id)
    );
  };

  // Function to handle filter changes
  const handleFilterChange = (filterName: string, value: any) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
  };

  // Function to handle adding to cart
  const handleAddToCart = (product: Product) => {
    // Check if the product is already in the cart
    if (!cartItems.find((item) => item.id === product.id)) {
      setCartItems((prevCartItems) => [...prevCartItems, product]);
    }
  };

  // Function to handle removing from cart
  const handleRemoveFromCart = (productId: string, selectedSize?: string) => {
    setCartItems((prevCartItems) =>
      prevCartItems.filter(
        (item) => !(item.id === productId && item.selectedSize === selectedSize)
      )
    );
  };

  // Update the handleSearch function
  const handleSearch = async (query: string) => {
    setIsSearching(true); // Set searching state to true
    setLoading(true);
    try {
      const searchParams = new URLSearchParams({
        query,
        limit: productsPerPage.toString(),
      });

      // Add optional filters
      if (filters.retailer) searchParams.append('retailer', filters.retailer);
      if (filters.gender) searchParams.append('gender', filters.gender);
      if (filters.general_category) searchParams.append('general_category', filters.general_category);
      if (filters.color) searchParams.append('color', filters.color);
      if (filters.priceRange[0]) searchParams.append('min_price', filters.priceRange[0].toString());
      if (filters.priceRange[1]) searchParams.append('max_price', filters.priceRange[1].toString());

      const url = `https://sage-ai-labs--singlestore-backend-search-products.modal.run?${searchParams.toString()}`;
      
      // Log the request details
      console.group('Product Search Request');
      console.log('Search Query:', query);
      console.log('Full URL:', url);
      console.log('Active Filters:', filters);
      console.timeStamp('Search Request Start');
      console.groupEnd();

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      
      // Log the response details
      console.group('Product Search Response');
      console.log('Response Status:', data.status);
      console.log('Product Count:', data.product_count);
      console.log('Has More:', data.next_start_after !== null);
      console.log('First Few Products:', data.products.slice(0, 3));
      console.timeStamp('Search Request End');
      console.groupEnd();

      if (data.status === '200') {
        setProducts(data.products);
        setLastProductId(data.next_start_after);
        setLastSimilarity(data.last_similarity);
        setHasMore(data.next_start_after !== null);
        
        // Log state updates
        console.group('State Updates');
        console.log('Updated Products Count:', data.products.length);
        console.log('Last Product ID:', data.next_start_after);
        console.log('Last Similarity Score:', data.last_similarity);
        console.log('Has More Products:', data.next_start_after !== null);
        console.groupEnd();
      } else {
        throw new Error(data.error || 'Failed to search products');
      }
    } catch (error) {
      // Enhanced error logging
      console.group('Search Error');
      console.error('Error Type:', error instanceof Error ? error.constructor.name : typeof error);
      console.error('Error Message:', error instanceof Error ? error.message : 'Unknown error');
      console.error('Error Stack:', error instanceof Error ? error.stack : 'No stack trace');
      console.groupEnd();
      
      setError(error instanceof Error ? error.message : 'Failed to search products');
    } finally {
      setLoading(false);
      setIsSearching(false); // Set searching state back to false
    }
  };

  const addProducts = (newProducts: Product[]) => {
    setProducts((prevProducts) => {
      // Create a Map for quick lookup to avoid duplicates
      const productMap = new Map<string, Product>();
      prevProducts.forEach((product) => {
        productMap.set(product.id, product);
      });
      newProducts.forEach((product) => {
        if (!productMap.has(product.id)) {
          productMap.set(product.id, product);
        }
      });
      return Array.from(productMap.values());
    });
  };

  return (
    <ImageCacheProvider>
      <Router>
        <div className="App">
          <Header />

          <Sidebar
            selectedTrendTag={filters.trendTag}
            onSelectTrendTag={(tag) => handleFilterChange('trendTag', tag)}
            isOpen={isSidebarOpen}
            ref={sidebarRef}
            filters={filters}
          />

          <main>
            {(loading && products.length === 0) || isSearching ? (
              <LoadingSpinner initialLoad={true} isSearching={isSearching} />
            ) : error ? (
              <p>{error}</p>
            ) : (
              <Routes>
                <Route
                  path="/"
                  element={
                    <div className={`main-content ${loading && products.length === 0 ? 'initial-loading' : ''}`}>
                      <div className="content-area">
                        <Filters 
                          filters={filters} 
                          onFilterChange={handleFilterChange}
                          onSearch={handleSearch}
                          isSearching={isSearching}
                        />
                        <Home
                          products={displayedProducts}
                          onToggleFavorite={handleToggleFavorite}
                          fetchMoreData={fetchMoreData}
                          hasMore={hasMore}
                          loading={loading}
                          isSearching={isSearching}
                        />
                      </div>
                    </div>
                  }
                />
                <Route
                  path="/foryou"
                  element={
                    <div className="main-content">
                      <Sidebar
                        selectedTrendTag={filters.trendTag}
                        onSelectTrendTag={(tag: string) => handleFilterChange('trendTag', tag)}
                        isOpen={isSidebarOpen}
                        ref={sidebarRef}
                        filters={filters}
                      />
                      <div className="content-area">
                        <ForYou
                          products={products}
                          onToggleFavorite={handleToggleFavorite}
                          fetchMoreData={fetchMoreData}
                          hasMore={hasMore}
                          loading={loading}
                        />
                      </div>
                    </div>
                  }
                />
                <Route
                  path="/favorites"
                  element={
                    <Favorites
                      products={favoriteProducts}
                      onToggleFavorite={handleToggleFavorite}
                    />
                  }
                />
                <Route
                  path="/product/:id"
                  element={
                    <ProductDetails
                      products={products}
                      cartItems={cartItems} // Pass cartItems to ProductDetails
                      onToggleFavorite={handleToggleFavorite}
                      onAddToCart={handleAddToCart}
                      addProducts={addProducts}
                    />
                  }
                />
                <Route
                  path="/cart"
                  element={
                    <Cart
                      cartItems={cartItems}
                      onRemoveFromCart={handleRemoveFromCart} // Pass the remove handler
                    />
                  }
                />
                <Route path="/checkout" element={<Checkout />} />
              </Routes>
            )}
          </main>
          <Footer />
        </div>
      </Router>
    </ImageCacheProvider>
  );
};

export default App;
