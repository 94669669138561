import React, { useState, useEffect } from 'react';
import { Product } from './ProductCard';

interface CartProps {
  cartItems: Product[];
  onRemoveFromCart: (productId: string, selectedSize?: string) => void;
}

const Cart: React.FC<CartProps> = ({ cartItems, onRemoveFromCart }) => {
  const [totalPrice, setTotalPrice] = useState<number>(0);

  useEffect(() => {
    const total = cartItems.reduce((sum, item) => {
      const price = parseFloat(item.current_price.replace(/[^0-9.-]+/g, ''));
      return sum + price;
    }, 0);
    setTotalPrice(total);
  }, [cartItems]);

  return (
    <div className="cart">
      <h2>Your Cart</h2>
      {cartItems.length > 0 ? (
        <div className="cart-items">
          {cartItems.map((item, index) => (
            <div className="cart-item" key={index}>
              <img src={item.images && item.images[0]} alt={item.title} />
              <div className="cart-item-details">
                <h3>{item.title}</h3>
                <p>Price: ${parseFloat(item.current_price.replace(/[^0-9.-]+/g, '')).toFixed(2)}</p>
                {item.selectedSize && <p>Size: {item.selectedSize}</p>}
                {item.color && <p>Color: {item.color}</p>}
                <button
                  className="remove-item-button"
                  onClick={() => onRemoveFromCart(item.id, item.selectedSize)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <div className="cart-total">
            <h3>Total: ${totalPrice.toFixed(2)}</h3>
          </div>
          <button className="checkout-button">Proceed to Checkout</button>
        </div>
      ) : (
        <p>Your cart is empty.</p>
      )}
    </div>
  );
};

export default Cart;