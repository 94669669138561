import React, { createContext, useState, useContext, useEffect } from 'react';

interface ImageCacheContextType {
  cachedImages: Set<string>;
  addToCache: (url: string) => void;
}

const ImageCacheContext = createContext<ImageCacheContextType | undefined>(undefined);

export const useImageCache = () => {
  const context = useContext(ImageCacheContext);
  if (!context) {
    throw new Error('useImageCache must be used within an ImageCacheProvider');
  }
  return context;
};

export const ImageCacheProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [cachedImages, setCachedImages] = useState<Set<string>>(new Set());

  const addToCache = (url: string) => {
    setCachedImages(prev => new Set(prev).add(url));
  };

  return (
    <ImageCacheContext.Provider value={{ cachedImages, addToCache }}>
      {children}
    </ImageCacheContext.Provider>
  );
};
