import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductList from './ProductList';
import { Product } from './ProductCard';

interface ForYouProps {
  products: Product[];
  onToggleFavorite: (product: Product) => void;
  fetchMoreData: () => void;
  hasMore: boolean;
  loading: boolean;
}

const ForYou: React.FC<ForYouProps> = ({
  products,
  onToggleFavorite,
  fetchMoreData,
  hasMore,
  loading,
}) => {
  return (
    <div className="home">
      <InfiniteScroll
        dataLength={products.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading more products...</h4>}
        style={{ overflow: 'visible' }}
      >
        {products.length > 0 ? (
          <ProductList products={products} onToggleFavorite={onToggleFavorite} />
        ) : (
          !loading && <p>No products available.</p>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default ForYou;
