import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProductCard.css'; // Import the CSS file for ProductCard
import { useImageCache } from '../contexts/ImageCacheContext';

export interface Product {
  id: string;
  product_id: string;
  original_url: string;
  product_hash: string;
  url: string;
  brand: string;
  retailer: string;
  formatted_retailer: string;
  title: string;
  description: string;
  current_price: string;
  currency: string;
  original_price: string;
  is_discounted: boolean;
  price_percentage_change: number;
  images: string[];
  color: string;
  sizes: {
    availableSizes: string[];
    allSizes: string[];
  };
  gender: string;
  tags: string[];
  category: string;
  review_data: any; // Adjust type as needed
  additional_info: any; // Adjust type as needed
  feature_vector1: any; // Adjust type as needed
  feature_vector2: any; // Adjust type as needed
  best_deal_c: number;
  best_deal_p: number;
  cover_photo: string;
  created_at: string;
  day_price_drop_amount_c: number;
  day_price_drop_amount_p: number;
  discount_data: any; // Adjust type as needed
  downloaded_image: string;
  downloaded_images: string[];
  fine_grained_category: string;
  general_category: string;
  has_any_deal: boolean;
  has_day_price_drop: boolean;
  has_month_price_drop: boolean;
  has_official_discount: boolean;
  has_week_price_drop: boolean;
  month_price_drop_amount_c: number;
  month_price_drop_amount_p: number;
  official_discount_amount_c: number;
  official_discount_amount_p: number;
  popularity_data: any; // Adjust type as needed
  price: any; // Adjust type as needed
  price_history: any; // Adjust type as needed
  product_handle: string;
  sage_sizing: any; // Adjust type as needed
  updated_at: string;
  week_price_drop_amount_c: number;
  week_price_drop_amount_p: number;
  isFavorite?: boolean;
  selectedSize?: string;
  clip_embedding_vector?: number[];
}

interface ProductCardProps {
  product: Product;
  onToggleFavorite: (product: Product) => void;
  cardWidth: number;
}

const useAutoSizeFont = (content: string, containerWidth: number) => {
  const [fontSize, setFontSize] = useState(16);
  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (textRef.current) {
      let currentSize = 16; // Start with default size
      const element = textRef.current;
      element.style.fontSize = `${currentSize}px`;
      
      while (element.scrollWidth > containerWidth && currentSize > 8) {
        currentSize--;
        element.style.fontSize = `${currentSize}px`;
      }
      
      setFontSize(currentSize);
    }
  }, [content, containerWidth]);

  return { fontSize, textRef };
};

const ProductCard: React.FC<ProductCardProps> = ({ product, onToggleFavorite, cardWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cachedImages, addToCache } = useImageCache();
  
  const retailerText = (product.formatted_retailer || product.retailer).replace(/_/g, ' ');
  const brandText = product.brand || retailerText;
  
  const { fontSize: retailerFontSize, textRef: retailerRef } = useAutoSizeFont(retailerText, cardWidth - 24);
  const { fontSize: brandFontSize, textRef: brandRef } = useAutoSizeFont(brandText, cardWidth - 24);
  const { fontSize: priceFontSize, textRef: priceRef } = useAutoSizeFont(product.current_price, cardWidth - 24);
  const { fontSize: originalPriceFontSize, textRef: originalPriceRef } = useAutoSizeFont(product.original_price, cardWidth - 24);

  const [clickCount, setClickCount] = useState(0);
  const clickTimer = useRef<NodeJS.Timeout>();
  const doubleClickDelay = 160; // milliseconds

  const handleCardClick = () => {
    setClickCount(prev => prev + 1);

    if (clickTimer.current) {
      clearTimeout(clickTimer.current);
    }

    clickTimer.current = setTimeout(() => {
      if (clickCount === 0) {
        // Single click - navigate to PDP
        sessionStorage.setItem('scrollPosition', window.scrollY.toString());
        sessionStorage.setItem('previousPath', location.pathname);
        navigate(`/product/${product.product_id || product.id}`);
      } else {
        // Double click - toggle favorite
        onToggleFavorite(product);
      }
      setClickCount(0);
    }, doubleClickDelay);
  };

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (clickTimer.current) {
        clearTimeout(clickTimer.current);
      }
    };
  }, []);

  const handleFavoriteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggleFavorite(product);
  };

  const renderImage = (imageUrl: string, index: number) => {
    const isPreloaded = cachedImages.has(imageUrl);
    return (
      <img 
        key={index}
        src={imageUrl} 
        alt={product.title} 
        className={`product-image ${isPreloaded ? 'preloaded' : ''}`}
        style={{ display: 'block' }}
        onLoad={() => addToCache(imageUrl)}
      />
    );
  };

  const formatPrice = (price: string) => {
    const numPrice = parseFloat(price);
    return numPrice.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  return (
    <div
      className={`product-card ${product.isFavorite ? 'favorited' : ''}`}
      onClick={handleCardClick}
    >
      <button 
        className="favorite-button" 
        onClick={handleFavoriteClick}
      >
        {product.isFavorite ? '💖' : '🤍'}
      </button>
      <div className="product-image-container">
        {product.images && product.images.length > 0 ? (
          <>
            {renderImage(product.images[0], 0)}
            {product.images[1] && renderImage(product.images[1], 1)}
          </>
        ) : (
          <div className="product-image-placeholder">
            No Image Available
          </div>
        )}
      </div>
      <div className="product-info-container">
        <div className="info-row">
          <span 
            ref={retailerRef}
            className="product-info retailer"
            style={{ 
              fontSize: `${retailerFontSize}px`, 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis',
            }}
          >
            {retailerText.toUpperCase()}
          </span>
          <span 
            ref={brandRef}
            className="product-info brand"
            style={{ 
              fontSize: `${brandFontSize}px`, 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis',
            }}
          >
            {brandText.toUpperCase()}
          </span>
          <div className="price-container">
            <span 
              ref={priceRef}
              className="current-price"
              style={{ fontSize: `${priceFontSize}px`, whiteSpace: 'nowrap' }}
            >
              ${formatPrice(product.current_price)}
            </span>
            {parseFloat(product.current_price) < parseFloat(product.original_price) && (
              <span 
                ref={originalPriceRef}
                className="original-price"
                style={{ fontSize: `${originalPriceFontSize}px`, whiteSpace: 'nowrap' }}
              >
                ${formatPrice(product.original_price)}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProductCard);
