import React from 'react';
import './LoadingSpinner.css';

interface LoadingSpinnerProps {
  initialLoad?: boolean;
  isSearching?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ 
  initialLoad = false,
  isSearching = false 
}) => {
  return (
    <div className={`loading-container ${initialLoad ? 'initial-load' : ''} ${isSearching ? 'searching' : ''}`}>
      <div className="cyber-scene">
        <div className="grid-plane"></div>
        <div className="geometric-container">
          <div className="dodecahedron">
            {[...Array(12)].map((_, index) => (
              <div key={index} className={`face face-${index + 1}`}>
                <div className="face-inner">
                  <div className="face-highlight"></div>
                  <div className="face-shadow"></div>
                </div>
              </div>
            ))}
          </div>
          <div className="orbital-ring crystal-ring"></div>
          <div className="orbital-ring-2 crystal-ring"></div>
          <div className="scan-lines crystal-scan"></div>
          <div className="crystal-glow"></div>
        </div>
      </div>
      {isSearching && <div className="searching-text">Searching...</div>}
    </div>
  );
};

export default LoadingSpinner;