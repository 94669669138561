import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProductCard, { Product } from './ProductCard';
import LazyImage from './LazyImage';
import axios from 'axios';

interface ProductDetailsProps {
  products: Product[];
  cartItems: Product[];
  onToggleFavorite: (product: Product) => void;
  onAddToCart: (product: Product) => void;
  addProducts: (products: Product[]) => void;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  products,
  cartItems,
  onToggleFavorite,
  onAddToCart,
  addProducts,
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const deltaAccumulator = useRef<number>(0);

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [similarProducts, setSimilarProducts] = useState<Product[]>([]);
  const [isLoadingSimilar, setIsLoadingSimilar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    console.log('Products array:', products);
    console.log('Looking for product with ID:', id);
  }, [products, id]);

  const product = products.find((p) => {
    console.log('Comparing:', p.product_id, id);
    return p.product_id === id;
  });

  useEffect(() => {
    // Reset all product-specific states when ID changes
    setSelectedImageIndex(0);
    setSimilarProducts([]);
    setIsLoadingSimilar(true);
    setSelectedSize('');
  }, [id]);

  useEffect(() => {
    // Force scroll to top immediately when component mounts
    window.scrollTo(0, 0);
    // Cleanup function to ensure scroll is enabled when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const fetchSimilarProducts = async () => {
      if (!product || !isLoadingSimilar) {
        return;
      }

      try {
        const params = new URLSearchParams({
          product_id: product.id,
          category: product.general_category,
          gender: product.gender || 'Men',
          limit: '100'
        });

        const url = `https://sage-ai-labs--singlestore-backend-fetch-similar.modal.run?${params.toString()}`;
        
        const response = await axios.get(url);

        if (response.data && response.data.products) {
          // Only update if we're still looking at the same product
          if (product.product_id === id) {
            setSimilarProducts(response.data.products);
            addProducts(response.data.products);
          }
        }
      } catch (error) {
        console.error('Error fetching similar products:', error);
      } finally {
        setIsLoadingSimilar(false);
      }
    };

    fetchSimilarProducts();
  }, [product, id, isLoadingSimilar, addProducts]);

  const handleBackClick = () => {
    navigate('/');
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  const images = product.images ?? [];

  const isInCart = cartItems.some(
    (item) => item.id === product.id && item.selectedSize === selectedSize
  );

  const handleToggleFavorite = () => {
    onToggleFavorite(product);
  };

  const handleAddToCartClick = () => {
    if (product.sizes && product.sizes.allSizes.length > 0 && !selectedSize) {
      alert('Please select a size before adding to cart');
      return;
    }
    onAddToCart({ ...product, selectedSize });
  };

  const handleWheel = (event: React.WheelEvent) => {
    event.preventDefault();

    if (images.length > 1) {
      // Accumulate the delta
      deltaAccumulator.current += Math.abs(event.deltaY);
      
      // Only change image if accumulated delta exceeds threshold
      if (deltaAccumulator.current >= 35) {
        if (event.deltaY > 0) {
          // Scroll down: show next image
          setSelectedImageIndex((prevIndex) =>
            prevIndex < images.length - 1 ? prevIndex + 1 : 0
          );
        } else if (event.deltaY < 0) {
          // Scroll up: show previous image
          setSelectedImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : images.length - 1
          );
        }
        // Reset accumulator after changing image
        deltaAccumulator.current = 0;
      }
    }
  };

  const formatPrice = (price: string) => {
    const numPrice = parseFloat(price);
    return numPrice.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const handleImageMouseEnter = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleImageMouseLeave = () => {
    document.body.style.overflow = 'auto';
  };

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.preventDefault();
    const scrollAmount = event.deltaY;
    const similarProductsList = document.querySelector('.similar-products-list') as HTMLElement;

    if (similarProductsList) {
      // Use a smaller multiplier for more precise scrolling
      const scrollMultiplier = 0.5;
      similarProductsList.scrollLeft += scrollAmount * scrollMultiplier;
    }
  };

  const SIMILAR_PRODUCT_CARD_WIDTH = 200; // Fixed width for similar product cards

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="product-details-page">
      {/* <button className="back-button" onClick={handleBackClick}>
        &larr; Back to Home
      </button> */}

      <div className="product-main-content">
        <div className="product-gallery">
          <div 
            className="main-image" 
            onWheel={handleWheel}
            onMouseEnter={handleImageMouseEnter}
            onMouseLeave={handleImageMouseLeave}
            onClick={handleImageClick}
          >
            {images.length > 0 ? (
              images.map((image, index) => (
                <LazyImage
                  key={index}
                  src={image}
                  alt={`${product.title} ${index + 1}`}
                  style={{
                    opacity: index === selectedImageIndex ? 1 : 0,
                    zIndex: index === selectedImageIndex ? 1 : 0,
                  }}
                />
              ))
            ) : (
              <div className="image-placeholder">No Image Available</div>
            )}
          </div>

          {images.length > 1 && (
            <div className="thumbnail-images">
              {images.map((image, index) => (
                <LazyImage
                  key={index}
                  src={image}
                  alt={`${product.title} ${index + 1}`}
                  onClick={() => setSelectedImageIndex(index)}
                  className={selectedImageIndex === index ? 'active' : ''}
                />
              ))}
            </div>
          )}
        </div>

        {isModalOpen && (
          <div className="image-modal" onClick={handleCloseModal}>
            <div className="modal-content">
              <button className="close-modal" onClick={handleCloseModal}>×</button>
              <LazyImage
                src={images[selectedImageIndex]}
                alt={`${product.title} ${selectedImageIndex + 1}`}
                className="modal-image"
              />
            </div>
          </div>
        )}

        <div className="product-info">
          <h1>{product.title}</h1>
          <div className="price-container">
            <span className="current-price">
              ${formatPrice(product.current_price)}
            </span>
            {parseFloat(product.current_price) < parseFloat(product.original_price) && (
              <>
                <span className="original-price">
                  ${formatPrice(product.original_price)}
                </span>
                <span className="discount-percentage">
                  {Math.round((1 - parseFloat(product.current_price) / parseFloat(product.original_price)) * 100)}% OFF
                </span>
              </>
            )}
          </div>
          <p className="category">Category: {product.category}</p>
          <p className="retailer">Retailer: {product.retailer}</p>

          {product.sizes?.allSizes && product.sizes?.availableSizes && (
            <div className="size-selector">
              <h3>Select a Size</h3>
              <div className="sizes">
                {product.sizes.allSizes.map((size) => {
                  const isAvailable =
                    product.sizes?.availableSizes?.includes(size);
                  return (
                    <button
                      key={size}
                      className={`size-button ${
                        selectedSize === size ? 'selected' : ''
                      } ${isAvailable ? '' : 'unavailable'}`}
                      onClick={() => isAvailable && setSelectedSize(size)}
                      disabled={!isAvailable}
                    >
                      {size}
                    </button>
                  );
                })}
              </div>
            </div>
          )}

          {product.color && (
            <p className="color">
              Color: {product.color}
              {/* <span
                className="color-swatch"
                style={{ backgroundColor: product.color }}
              /> */}
            </p>
          )}

          {product.description && (
            <div className="product-description">
              <h2>Description</h2>
              <p>{product.description}</p>
            </div>
          )}

          <div className="product-actions">
            <button
              className={`add-to-cart-button ${isInCart ? 'added' : ''}`}
              onClick={handleAddToCartClick}
              disabled={isInCart}
            >
              {isInCart ? 'Added to Cart' : 'Add to Cart'}
            </button>

            <button className="favorite-button-pdp" onClick={handleToggleFavorite}>
              {product.isFavorite
                ? '💖 Remove from Favorites'
                : '🤍 Add to Favorites'}
            </button>
          </div>
        </div>
      </div>

      {/* Similar Products Section */}
      <div className="similar-products-section">
        {/* <h2>Similar Products</h2> */}
        <div className="similar-products-list" onWheel={handleScroll}>
          {isLoadingSimilar ? (
            <div className="loading-similar">Loading similar products...</div>
          ) : (
            similarProducts.map((similarProduct) => (
              <ProductCard
                key={similarProduct.product_id}
                product={similarProduct}
                onToggleFavorite={onToggleFavorite}
                cardWidth={SIMILAR_PRODUCT_CARD_WIDTH}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
