import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaHeart, FaShoppingCart } from 'react-icons/fa';

const Header: React.FC = () => (
  <header data-element="header">
    <nav className="nav-left">
      <Link to="/">
        <span className="desktop-text">Home</span>
        <FaHome className="mobile-icon" />
      </Link>
    </nav>
    
    <div className="header-title">
      <h1>Seldom</h1>
    </div>
    
    <nav className="nav-right">
      <Link to="/favorites" className="nav-link">
        <span className="desktop-text">Favorites</span>
        <FaHeart className="mobile-icon" />
      </Link>
      <Link to="/cart" className="nav-link">
        <span className="desktop-text">Cart</span>
        <FaShoppingCart className="mobile-icon" />
      </Link>
    </nav>
  </header>
);

export default Header;