import React, { useEffect, useState, forwardRef } from 'react';

interface SidebarProps {
  selectedTrendTag: string;
  onSelectTrendTag: (tag: string) => void;
  isOpen: boolean;
  filters: { gender: string };
}

const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(
  ({ selectedTrendTag, onSelectTrendTag, isOpen, filters }, ref) => {
    const [trendTags, setTrendTags] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      const fetchTrendTags = async () => {
        try {
          const gender = filters.gender === 'Women' ? 'W' : 'M';
          const response = await fetch(`https://sage-ai-labs--singlestore-backend-fetch-trend-tags.modal.run?gender=${gender}`);
          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
          const data = await response.json();
          setTrendTags(data.trend_tags);
        } catch (err: any) {
          setError(err.message || 'An unexpected error occurred');
        } finally {
          setLoading(false);
        }
      };

      fetchTrendTags();
    }, [filters.gender]);

    if (loading) {
      return (
        <aside ref={ref} className={`sidebar ${isOpen ? 'open' : ''}`}>
          <p>Loading trends...</p>
        </aside>
      );
    }

    if (error) {
      return (
        <aside ref={ref} className={`sidebar ${isOpen ? 'open' : ''}`}>
          <p>Error fetching trends: {error}</p>
        </aside>
      );
    }

    return (
      <aside ref={ref} className={`sidebar ${isOpen ? 'open' : ''}`}>
        <ul>
          <li
            key="all"
            className={`all-trends ${selectedTrendTag === '' ? 'active' : ''}`}
            onClick={() => onSelectTrendTag('')}
          >
            <strong>ALL TRENDS</strong>
          </li>
          {trendTags.map((tag) => (
            <li
              key={tag}
              className={selectedTrendTag === tag ? 'active' : ''}
              onClick={() => onSelectTrendTag(tag)}
            >
              {tag.toUpperCase()}
            </li>
          ))}
        </ul>
      </aside>
    );
  }
);

Sidebar.displayName = 'Sidebar';

export default Sidebar;
