import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductList from './ProductList';
import { Product } from './ProductCard';
import { useLocation } from 'react-router-dom';
import { useImageCache } from '../contexts/ImageCacheContext';
import LoadingSpinner from './LoadingSpinner';

interface HomeProps {
  products: Product[];
  onToggleFavorite: (product: Product) => void;
  fetchMoreData: () => void;
  hasMore: boolean;
  loading: boolean;
  isSearching?: boolean;
}

const Home: React.FC<HomeProps> = ({
  products,
  onToggleFavorite,
  fetchMoreData,
  hasMore,
  loading,
  isSearching = false
}) => {
  const location = useLocation();
  const { cachedImages } = useImageCache();

  // Added state to track the visibility of the Back to Top button
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      // Show button when scrolled down 400 pixels
      if (window.scrollY > 400) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to handle scrolling back to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };

  useEffect(() => {
    const savedPosition = sessionStorage.getItem('scrollPosition');
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition));
      sessionStorage.removeItem('scrollPosition');
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.scrollPosition) {
      window.scrollTo(0, location.state.scrollPosition);
    }
  }, [location]);

  // Use the total number of unfiltered products for dataLength
  const dataLength = products.length;

  return (
    <div className="home">
      <InfiniteScroll
        dataLength={dataLength}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<LoadingSpinner initialLoad={products.length === 0} />}
        style={{ overflow: 'visible', paddingTop: '20px' }}
        scrollThreshold="50%"
      >
        {products.length > 0 ? (
          <ProductList products={products} onToggleFavorite={onToggleFavorite} />
        ) : (
          !loading && <p>No products available.</p>
        )}
      </InfiniteScroll>

      {/* Back to Top button */}
      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          &#8679; Back to Top
        </button>
      )}
    </div>
  );
};

export default Home;
